body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100% !important;
}

div.react-datepicker__input-container > input {
  width: 95% !important;
  padding: 0.5rem !important;
}
.gm-style-iw-c {
  max-width: 999px !important;
  max-height: 999px !important;
  background-color: #f8f9fa !important;
}
.gm-style-iw-ch {
  padding-top: 1px !important;
}
.gm-style-iw-d {
  overflow: hidden !important;
  max-height: 950px !important;
}
.gm-style .gm-style-iw {
  font-weight: 400;
  font-size: 13px;
  overflow: hidden;
}
.form-label {
  margin-bottom: 0.5rem !important;
}
.g-3,
.gy-3 {
  --bs-gutter-y: 1rem !important;
}
.form-control,
.form-select {
  font-weight: 300 !important;
}
/* .p-component .p-fileupload-content
{
  height: 20px !important;
}
.pi pi-image mt-3 p-5 
{
  font-size: 1.5rem !important;
  border-radius: 50%;
  background-color: var(--surface-b);
  color: var(--surface-d);
} */
.p-fileupload-row {
  display: none !important;
}
.p-fileupload .p-fileupload-content {
  background: #00000000 !important;
  padding: 0rem 0rem !important;
  border: 1px solid #e5e7eb;
  color: #4b5563;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
/* .react-tabs__tab-list {
  border-bottom: 11px solid #AAE !important;
  margin: 0 0 10px;
  padding: 0;
} */
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(199, 195, 195);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

::-webkit-scrollbar:horizontal {
  height: 7px;
  background: rgba(199, 195, 195, 0.3);
}
::-webkit-scrollbar-thumb:horizontal {
  background: #555;
  border-radius: 10px;
}

.navbar-buttons.open {
  position: absolute;
  left: 0;
  top: 50px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}
.navbar-buttons.open .nav-button {
  margin-bottom: 5px;
}
.profile-submenu {
  width: 160px;
}
.signin-form {
  max-width: 30vw;
  width: 100%;
  padding: 30px;
}
.signin-form img {
  width: 100%;
}
.gm-style-iw-d {
  overflow: hidden !important;
  height: 760px !important;
}
.gm-style-iw-d .MuiBox-root {
  box-shadow: none;
}
.map-form {
  width: 40vw;
  max-height: 180vh;
  width: 100%;
}
.map-form .map-form-fields {
  margin-top: 30px;
  width: 100%;
}
.map-form .map-form-fields .form-style {
  padding: 0;
  box-shadow: none;
  width: 100%;
  margin: 0px auto;
}
.map-form .map-form-fields .form-style .MuiFormControl-root .MuiInputBase-root {
  text-align: left;
}
.serach-box {
  position: relative;
  left: 40%;
}
.search-input {
  position: inherit;
  left: auto;
  margin-left: 0px;
}
.serach-box button {
  position: absolute;
  top: 11px;
  right: -95px;
}

th.MuiTableCell-root.MuiTableCell-head {
  background-color: #330066 !important;
  color: #fff !important;
  text-align: center !important;
}
/* button.MuiButtonBase-root.MuiIconButton-root {
  display: none;
} */

.gm-style .gm-style-iw-c {
  padding-inline-end: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  min-width: 0px;
  width: 100%;
  height: auto;
  margin: 45px 0px;
  top: -60px;
  max-width: 1000px !important;
  max-height: 550px !important;
}
.gm-style .gm-style-iw-c button.gm-ui-hover-effect {
  position: absolute !important;
  right: 10px;
  top: 5px;
  z-index: 99;
}
.gm-style .gm-style-iw-d {
  overflow: scroll !important;
}
/* .gm-style .gm-style-iw-c .map-form table {
  width: 945px;
  overflow-x: auto;
} */
/* .gm-style .gm-style-iw-c {
  padding-inline-end: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  min-width: 0px;
  width: 100%;
  height: auto;
  margin: 45px 0px;
  top: 175px;
  max-width: 600px !important;
  max-height: 999px !important;
}

.gm-style {
  overflow: scroll;
} */
.new-site-category {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
  min-width: 600px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.m-super-admin table tr td:first-child {
  background-color: #fff;
}
.exp-docs {
  width: 100%;
  background-color: #fff;
  margin-bottom: 80px;
}
.settings {
  width: 100%;
  background-color: #fff;
  margin-bottom: 80px;
}
.asset-details .MuiGrid-container {
  margin-bottom: 8px;
}
.asset-details h6 {
  font-size: 1rem;
}
.edit_btn {
  position: absolute;
  top: 19px;
  right: 70px;
  width: auto;
}
.edit_btn svg {
  width: 0.8em;
  height: 0.8em;
  margin-top: -3px;
  margin-right: 5px;
}
.edit-category-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
  min-width: 600px;
  overflow: auto;
}
.doc-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 20px;
}
.doc-filters .doc-filter-fields {
  display: flex;
  align-items: center;
  gap: 10px;
}
.edit_doc {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
  min-width: 600px;
  max-width: 1000px;
  max-height: calc(100vh - 100px);
  overflow: auto;
}
@media (max-width: 1444px) {
  .serach-box {
    position: relative;
    left: 35%;
  }
}
@media (max-width: 1024px) {
  .serach-box {
    position: relative;
    left: 30%;
  }
  .signin-form {
    max-width: 50vw;
    width: 100%;
    padding: 30px;
  }
  .gm-style-iw-d {
    height: 500px !important;
  }
  .map-form .map-form-fields .form-style {
    width: 100%;
    display: block;
    text-align: center;
    margin: 0px auto;
    box-shadow: none;
    height: 40vh;
    overflow-y: auto;
    padding: 0;
  }
  .map-form .map-form-fields .form-style .MuiGrid-container {
    margin-left: 0px;
    padding-top: 10px;
  }
  .map-form .map-form-fields .form-style .MuiGrid-container .MuiGrid-item {
    padding-left: 0px;
  }
  .gm-style .gm-style-iw-c {
    max-width: 900px !important;
  }
}
@media (max-width: 899px) {
  .map-form .map-form-fields .form-style {
    height: 40vh;
  }
  .m-super-admin table tr td:last-child,
  .m-super-admin table tr td:last-child {
    text-align: left !important;
  }
  .m-super-admin table tr td:last-child .actions-wrapper {
    justify-content: flex-start;
  }
  .m-super-admin table .ant-btn {
    padding: 4px 10px;
  }
  .m-super-admin table .ant-btn svg {
    width: 0.875rem;
    height: 0.875rem;
  }
  .m-super-admin table tr td div:first-child {
    font-weight: 600;
  }
  .m-super-admin table tr td div.datatables-noprint {
    width: 100%;
  }
  table .MuiTablePagination-root .MuiTablePagination-selectLabel {
    display: none;
  }
  .new-site-category {
    min-width: 60vw;
  }
  .gm-style .gm-style-iw-c {
    max-width: 90vw !important;
  }
}
@media (max-width: 767px) {
  .edit-category-popup {
    min-width: 300px;
    width: 90%;
    max-height: 70vh;
  }
  .edit_doc {
    min-width: 300px;
    width: 90%;
    max-height: 70vh;
  }
  .doc-filters {
    padding: 10px;
    margin: 10px;
  }
  .doc-filters .doc-filter-fields {
    gap: 5px;
  }
  .asset-details .hidden-xs {
    display: none;
  }
  .asset-details .MuiGrid-container .MuiGrid-item:last-child {
    padding-top: 0px;
  }
  .signin-form {
    max-width: 50vw;
    width: 100%;
    padding: 30px;
  }
  .gm-style .gm-style-iw-c {
    top: -60px;
    max-width: 90vw !important;
    max-height: 450px !important;
  }
  .gm-style-iw-d {
    height: 400px !important;
  }
  .gm-style .gm-style-iw-d {
    overflow: hidden !important;
  }
  .map-form {
    width: 100% !important;
  }
  .map-form .map-form-fields {
    height: 35vh !important;
    overflow-y: auto;
  }
  .gm-style .gm-style-iw-c .map-form table {
    width: 100%;
    overflow-x: auto;
  }
  .serach-box {
    position: relative;
    left: 12%;
    top: 50px;
  }
  .new-site-category {
    min-width: 80vw;
  }
  .exp-docs .MuiTabs-flexContainer {
    width: 400px;
    overflow-x: auto;
  }
  .settings .MuiTabs-flexContainer {
    width: 350px;
    overflow-x: auto;
  }
  .edit_btn {
    position: absolute;
    top: 50px;
    right: 30px;
    width: auto;
  }
}
@media (max-width: 440px) {
  .doc-filters .doc-filter-fields .MuiFormControl-root {
    width: 120px !important;
  }
  .serach-box {
    position: relative;
    left: 6%;
    top: 50px;
  }
  .serach-box button {
    position: absolute;
    top: 11px;
    right: 0px;
  }
  .signin-form {
    max-width: 70vw;
    width: 100%;
    padding: 20px;
  }
  .gm-style .gm-style-iw-c {
    top: -35px;
    max-width: 90vw !important;
    max-height: 550px !important;
  }
  .map-form {
    width: 100% !important;
  }
  .map-form .MuiTabs-scroller {
    overflow-x: auto !important;
  }
}

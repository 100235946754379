.width-95 {
	width: 95%;
}

.form-style {
	padding: 1rem;
	min-height: 80% !important;
	min-width: 80% !important;
	background-color: #f8f9fa;
	border-radius: 4px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.button {
	width: 150px;
}

.center {
	display: flex;
	justify-content: center;
}

.date-picker {
	width: 95% !important;
	padding: 0.5rem !important;
	border: 1px solid black !important;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* BackgroundVideo.css */

.video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none; /* Hide all videos initially */
}

.background-video.show {
    display: block; /* Show the current video */
}

.fallback-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

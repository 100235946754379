.search-input {
	box-sizing: border-box;
	border: 1px solid transparent;
	width: 20rem;
	height: 2.5rem;
	padding: 0 12px;
	border-radius: 3px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	font-size: 14px;
	outline: none;
	text-overflow: ellipsis;
	position: absolute;
	left: 50%;
	margin-left: -120px;
	margin-top: 10px;
}

.tab-text {
	font-size: 18px;
}

.custom-tab-list {
	border-bottom: 11px solid #aae !important;
	margin: 0 0 10px;
	padding: 0;
}

.marker-label {
	color: red !important;
	margin-bottom: 2.5rem;
	font-weight: bold !important;
	font-size: 16px !important;
}

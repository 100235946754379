.document-tabs {
  margin: 20px;
}

.nav-tabs {
  display: flex;
  justify-content: space-around;
  border: none;
}

.table {
  margin-top: 10px;
}

.nav-tabs .nav-link {
  border: none;
}

.nav-tabs .nav-link.active {
  border: 2px solid green; /* Add a green border for the active tab */
}

.button-container {
  display: flex;
  justify-content: center !important; /* Center the button */
  margin-top: 20px; /* Add some space above the button */
}
.MuiTablePagination-root p {
  margin: 0;
}

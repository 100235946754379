.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 20px;
	background-color: #333;
	color: white;
	position: fixed;
	width: 100%;
	bottom: 0;
	text-align: center;
}

.footer-copy {
	font-size: 0.9em;
}

.button-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.form-style {
    padding: 1rem;
    min-height: 80% !important;
    min-width: 80% !important;
    background-color: #f8f9fa;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.width-95{
    width: 95%;
}
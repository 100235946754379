.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	background-color: #333;
	color: #fff;
	z-index: 2;
}

.navbar-logo img {
	height: 40px;
	background-color: white;
}

.navbar-buttons {
	display: flex;
	align-items: center;
}

.navbar-buttons.open {
	display: block;
}

.nav-button {
	margin: 0 10px;
	color: #fff;
	text-decoration: none;
}

.nav-button.active {
	background-color: #555;
	padding: 10px; /* Change this to whatever color you want for the active link */
}

.profile-menu {
	position: relative;
}

.profile-icon {
	background: none;
	border: none;
	cursor: pointer;
}

.icon {
	height: 25px;
	width: 25px;
}

.profile-icon img {
	height: 40px;
}

.profile-submenu {
	position: absolute;
	top: 50px;
	right: 0;
	background-color: #fff;
	color: #333;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	overflow: hidden;
}

.profile-submenu a {
	display: block;
	padding: 10px;
	text-decoration: none;
	color: #333;
}

.profile-submenu a:hover {
	background-color: #ddd;
}

.navbar-toggle {
	display: none;
}

@media (max-width: 768px) {
	.navbar-buttons {
		display: none;
		flex-direction: column;
		width: 100%;
	}

	.navbar-buttons.open {
		display: flex;
	}

	.navbar-toggle {
		display: block;
		background: none;
		border: none;
		color: #fff;
		font-size: 24px;
		cursor: pointer;
	}
}

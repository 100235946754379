.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h5 {
  margin: 0;
}

.mt-5 {
  margin-top: 3rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.table {
  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

.table-striped tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.btn {
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.btn-success {
  background-color: #28a745;
  color: white;
}

.btn-success:hover {
  background-color: #218838;
}

.btn-warning {
  background-color: #ffc107;
  color: black;
}

.btn-warning:hover {
  background-color: #e0a800;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}

.btn-danger:hover {
  background-color: #c82333;
}

/* Modal styles */
.modal {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.modal-dialog {
  background-color: white;
  border-radius: 5px;
  max-width: 500px;
  width: 90%;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  border-top: 1px solid #dee2e6;
}

/* Action buttons styles */
.action-buttons {
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
  margin-left: 0px;
  width: 100%;
}

.delete-button {
  margin-right: 10px;
}

.middle-buttons {
  display: flex;
  justify-content: center; /* Centers the Activate/Deactivate button */
  margin: 0 10px; /* Adds some horizontal spacing */
}

.edit-button {
  margin-left: auto; /* Aligns the Edit button to the right */
}

@media (max-width: 768px) {
  .btn {
    margin-bottom: 10px;
  }

  .table th,
  .table td {
    font-size: 12px;
    padding: 5px;
  }

  .modal-dialog {
    max-width: 90%;
  }

  .action-buttons {
    flex-direction: column; /* Stacks buttons on smaller screens */
    gap: 10px; /* Space between stacked buttons */
    justify-content: center;
    align-items: center;
  }
}

.action-btn {
  width: 100px;
}

.reset-button {
  width: 150px;
  margin-right: auto;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.mr {
  margin-right: 12px !important;
}

.row-light {
  background-color: #f9f9f9;
}

.row-dark {
  background-color: #e0e0e0;
}

.custom-table .ant-table-container {
  border: 2px solid #333; /* Custom table border */
  margin-top: 2rem;
}

.custom-table .ant-table-thead > tr > th,
.custom-table .ant-table-tbody > tr > td {
  border-right: 1px solid #ccc; /* Custom column division lines */
}

.custom-table .ant-table-thead > tr > th:last-child,
.custom-table .ant-table-tbody > tr > td:last-child {
  border-right: none; /* Remove right border on the last column */
}

.custom-table .ant-table-tbody > tr {
  border-bottom: 1px solid #ccc; /* Row border */
}

.custom-table .ant-table-tbody > tr:last-child > td {
  border-bottom: none; /* Remove bottom border on the last row */
}
.m-super-admin table tr th button {
  text-align: left;
}
.tss-1akey0g-MUIDataTableHeadCell-data {
  text-transform: capitalize !important;
}
@media (max-width: 899.95px) {
  .m-super-admin table tr td div {
    overflow-wrap: anywhere;
  }
  .add-btns button {
    margin-bottom: 10px;
  }
}
h6.MuiTypography-root.MuiTypography-h6.tss-hj53wm-MUIDataTableToolbar-titleText.css-2ulfj5-MuiTypography-root {
  font-weight: bold;
  font-size: 1rem;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation4.tss-11quiee-MUIDataTable-paper.tss-1x5mjc5-MUIDataTable-root.m-super-admin.css-11mde6h-MuiPaper-root {
  margin-bottom: 40px;
}
/* th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.tss-10syd3x-MUIDataTableHeadCell-root.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader.css-1ygcj2i-MuiTableCell-root {
  background-color: #330066;
  color: #fff;
} */
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.tss-10syd3x-MUIDataTableHeadCell-root.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader.css-1ygcj2i-MuiTableCell-root {
  text-align: center !important;
}
.Mui-active .MuiTableSortLabel-icon {
  opacity: 1;
  color: #fff !important;
}
.tss-bd2lw8-MUIDataTableHeadCell-sortActive {
  color: #fff !important;
}
.table-container {
  height: 400px; /* Adjust the height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
}
.breadcrumb-img {
  padding: 0;
  position: relative;
  background-position: 50%;
  text-align: center;
  height: 120px;
  overflow: hidden;
}
.bread-img {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
  width: 100%;
}
/* .breadcrumb-img:before {
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  transform: rotate(2deg);
  content: "";
  position: absolute;
  width: 51%;
  height: 38px;
  background-color: #ffffff;
  bottom: -18px;
  left: 0px;
  z-index: 1;
}
.breadcrumb-img:after {
  -webkit-transform: rotate(-2deg);
  -moz-transform: rotate(-2deg);
  -ms-transform: rotate(-2deg);
  -o-transform: rotate(-2deg);
  transform: rotate(-2deg);
  content: "";
  position: absolute;
  width: 50%;
  height: 38px;
  background-color: #ffffff;
  bottom: -18px;
  right: 0px;
  z-index: 1;
} */
.breadcrumb-img .subheader-transparent {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-color: #000;
}
.breadcrumb-img .breadcrumb-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}
.MuiTableHead-root th.MuiTableCell-root {
  padding: 6px 14px;
}
td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.tss-1qtl85h-MUIDataTableBodyCell-root.tss-1y3wvy9-MUIDataTableBodyCell-stackedParent.tss-iwylj0-MUIDataTableBodyCell-responsiveStackedSmallParent.css-1ex1afd-MuiTableCell-root {
  text-align: center;
}
.actions-wrapper {
  display: flex;
  justify-content: center;
}
